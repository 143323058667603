@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~material-design-icons/iconfont/material-icons.css';

body {
    margin: 0;
    font-family: "Roboto", "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
    body {
        @apply bg-gray-200 font-sans text-gray-800;
    }

    h1 {
        @apply text-3xl font-bold tracking-wide py-4;
    }

    h2 {
        @apply text-2xl font-bold pt-4 pb-2;
    }

    label {
        @apply block text-sm font-medium text-gray-700;
    }

    input[type='text'], input[type='password'], select, textarea {
        @apply mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm;
    }

    input[type='text'].error, input[type='password'].error, select.error, textarea.error {
        @apply border-red-500;
    }

    label.error {
        @apply text-red-500;
    }

    input[type='text']:read-only {
        @apply border-gray-100 bg-gray-100;
    }

    input[type='text']:disabled, input[type='password']:disabled, select:disabled, textarea:disabled {
        @apply bg-gray-200;
    }

    header {
        @apply bg-gray-900 text-white;
    }

    nav {
        @apply flex flex-col lg:flex-row;
    }

    nav a, nav button {
        @apply block lg:px-4 lg:py-4 px-2 py-2 text-white no-underline hover:bg-gray-400 hover:text-gray-900;
    }

    nav a.active {
        @apply bg-gray-200 text-gray-900;
    }
}

@layer components {
    .btn {
        @apply inline-flex justify-center py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
    }

    .link {
        @apply text-indigo-800 underline cursor-pointer;
    }

    .btn.white {
        @apply bg-gray-200 hover:bg-gray-300 text-gray-700 focus:ring-gray-300;
    }

    .btn.red {
        @apply bg-red-600 hover:bg-red-700 focus:ring-red-500;
    }

    .btn.green {
        @apply bg-green-600 hover:bg-green-700 focus:ring-green-500;
    }

    .btn.yellow {
        @apply bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500;
    }

    .btn.small {
        @apply py-0 ml-0;
    }

    .btnx {
        -moz-box-shadow: inset 0px 1px 0px 0px #fce2c1;
        -webkit-box-shadow: inset 0px 1px 0px 0px #fce2c1;
        box-shadow: inset 0px 1px 0px 0px #fce2c1;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffc477), color-stop(1, #fb9e25));
        background: -moz-linear-gradient(center top, #ffc477 5%, #fb9e25 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc477', endColorstr='#fb9e25');
        background-color: #ffc477;
        border-radius: 10px;
        text-indent: 0;
        border: 5px solid #eeb44f;
        display: inline-block;
        color: #000;
        font-family: "PT Sans";
        font-size: 18px;
        font-style: normal;
        height: 45px;
        line-height: 28px;
        text-decoration: none;
        text-align: center;
        text-shadow: 1px 1px 0px #cc9f52;
        padding: 0 24px;
        margin: 4px 8px;
    }

    .btnx:hover {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fb9e25), color-stop(1, #ffc477));
        background: -moz-linear-gradient(center top, #fb9e25 5%, #ffc477 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb9e25', endColorstr='#ffc477');
        background-color: #fb9e25;
    }

    .w-container {
        @apply shadow-md border border-gray-300 bg-white px-4 py-4 my-4;
    }

    .w-blk {
        @apply mx-2 my-2 border border-l-8 cursor-pointer text-gray-800 hover:bg-gray-100 shadow-md rounded-md shadow-sm;
    }

    ul.pagination {

    }

    ul.pagination li {
        @apply inline-block border border-gray-200 bg-gray-100 hover:bg-gray-200 mx-1 my-1 text-center;
    }

    ul.pagination li.active {
        @apply border-gray-500;
    }

    ul.pagination li a {
        @apply no-underline text-gray-800 px-2 py-1;
    }

    .icon {
        font-family: "Material Icons";
        font-size: 24pt;
    }
}

.square {

}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
